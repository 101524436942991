import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button } from '@mui/material';
import './Register.css';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

function Register() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    con_password: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user.password !== user.con_password) {
      setError('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post('https://ebaazar.sanichar.online/api/register/', {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        con_password: user.con_password,
      });

      console.log('User registered successfully:', response.data);
      setSuccess('User registered successfully!');
      setError(null);

      // Clear the form
      setUser({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        con_password: ''
      });
    } catch (error) {
      console.error('Error registering user:', error.response?.data || error.message);
      setError(error.response?.data.errors || 'Registration failed! Please try again.');
      setSuccess(null);
    }
  };

  const PrivacyClick = () => {
    navigate('/privacy');
  };

  return (
    <>
      <HoverDropdown />
      <div className="section-margin-top-bottom">
        <div className="register-Container">
          <div className='row justify-content-center'>
            <div className='col-11 col-md-11 col-lg-8 col-xl-7'>
              <div className="register-form">
                <div className="register-left-content">
                  <Typography variant="h5" color="white" className="mb-3">Create Your Account</Typography>
                  <p>Sign up to start using Ebazaar for all your orders, wishlist, and recommendations.</p>
                  <img
                    src="https://cdni.iconscout.com/illustration/premium/thumb/login-page-illustration-download-in-svg-png-gif-file-formats--app-developing-development-secure-mobile-webapp-and-pack-design-illustrations-3783954.png"
                    alt="Register"
                    className="bottom-image"
                  />
                </div>
                <div className="register-right-content">
                  <TextField
                    label="First Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="first_name"
                    value={user.first_name}
                    onChange={handleInputChange}
                    required
                  />
                  <TextField
                    label="Last Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="last_name"
                    value={user.last_name}
                    onChange={handleInputChange}
                    required
                  />
                  <TextField
                    label="Email"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                    required
                  />
                  <TextField
                    label="Phone"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="phone"
                    value={user.phone}
                    onChange={handleInputChange}
                    required
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="password"
                    value={user.password}
                    onChange={handleInputChange}
                    required
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="con_password"
                    value={user.con_password}
                    onChange={handleInputChange}
                    required
                  />
                  <Typography className="small" variant="body1" align="center">
                    By continuing, you agree to Ebazaar's{' '}
                    <a href="#">Terms of Use</a> and{' '}
                    <a href="#" onClick={PrivacyClick}>
                      Privacy Policy.
                    </a>
                  </Typography>

                  <Button variant="contained" className="btn-register mb-3" fullWidth onClick={handleSubmit}>
                    Register
                  </Button>

                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="alert alert-success" role="alert">
                      {success}
                    </div>
                  )}

                  <div className="register-info">
                    <Typography variant="body2" align="center">
                      Already have an account? <Link to="/login">Log In</Link>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
