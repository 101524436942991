import React from 'react';
import './GiftCard.css'
// Sample Gift Cards Array
const GiftCards = [
    {
        id: 1,
        image: "https://rukminim2.flixcart.com/fk-p-flap/480/480/image/a3d4f655b19c0f68.jpg?q=50",
    },
    {
        id: 2,
        image: "https://rukminim2.flixcart.com/fk-p-flap/480/480/image/a3d4f655b19c0f68.jpg?q=50",
    },
    {
        id: 3,
        image: "https://rukminim2.flixcart.com/fk-p-flap/480/480/image/a3d4f655b19c0f68.jpg?q=50",
    },
    {
        id: 4,
        image: "https://rukminim2.flixcart.com/fk-p-flap/480/480/image/a3d4f655b19c0f68.jpg?q=50",
    },
];

const GiftCardPage = () => {
    return (
        <>
            <div className="container giftCard  padding-20-15 white-section section-margin-top section-margin-bottom product-list-containet">
                {/* Intro Section */}
                <h2 className="text-start mb-4">Gift Cards for Every Occasion</h2>
                <p className="mb-3">
                    Discover our range of beautifully designed gift cards, perfect for any celebration. Choose from a variety of styles and send joy to your loved ones with a special touch.
                </p>

                {/* Gift Cards Section */}
                <h5 className='headline'>Shop By Price</h5>
                <div className="row">
                    {GiftCards.map((card) => (
                        <div key={card.id} className="col-6 col-lg-3 mb-4">
                            <div className="card">
                                <img src={card.image} alt={`Gift Card ${card.id}`} className="card-img-top" />
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <img className='img-fluid' src='https://rukminim2.flixcart.com/fk-p-flap/2000/2000/image/37406a98c93c3cb7.jpg?q=50' />
                </div>
                <div className='card mb-2 card-infox'>
                    <div className='card-body'>
                        <h5>EBAZAAR Gift Cards - For Those Special Moments</h5>
                        <p className='font-13'>Gift Cards provide an ideal gifting solution for all occasions. In addition to the ease of gifting, they also provide a choice to the individual to buy something they want and when they want.
                            If you can’t decide what to get someone, there’s a simple solution for this - Go to the Gift Cards Section on EBAZAAR. From something as small as fashion accessories and groceries to travel coupons and jewellery vouchers, gift vouchers offer a great way to let the recipient choose what they want. Gift Cards range from Rs. 50 to Rs. 10,000 and above. You can choose one as per your wish and the category you want to choose. You can gift them EBAZAAR Gift Cards or choose from 100+ brands like Myntra, Tanishq, Croma, Google Play, Big Bazar & others from categories such as Fashion, Travel, Jewellery, Grocery, Apps, and Subscriptions.</p>
                    </div>
                </div>
                <div className='card card-infox'>
                    <div className='card-body'>
                        <h5>Buy EBAZAAR Gift Cards & Vouchers Online</h5>
                        <p className='font-13'>Purchasing gift vouchers online is very simple. At the product page, select the value of the gift card you would like to purchase, click on buy now, and then enter the recipient details, address (if required), and 'proceed to pay’. You can then pay using a credit card, debit card, net banking & UPI. After the checkout process, you will receive an email with the Gift card details.</p>
                        <p className='font-13'>If you have irregular working hours and do not have time to shop, or if a special event is around the corner and you’re still wondering what to get, then EBAZAAR gift cards are the best option for you. Send your loved ones EBAZAAR vouchers and give them the freedom of choosing what their heart desires the most.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GiftCardPage;
