import React, { useState, useEffect } from 'react';
import './ProductFilter.css';
import { useNavigate, Link } from 'react-router-dom';
import api from '../axiosConfig'; // Import the Axios instance

const ProductFilter = (filters) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [selectedMinPrice, setSelectedMinPrice] = useState(minPrice);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(maxPrice);

  useEffect(() => {
    // Fetch categories
    fetch('https://ebaazar.sanichar.online/api/categories/')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));

    // Fetch brands
    fetch('https://ebaazar.sanichar.online/api/brands/')
      .then((response) => response.json())
      .then((data) => setBrands(data))
      .catch((error) => console.error('Error fetching brands:', error));
  }, []);

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (event.target.name === 'minPrice') {
      setSelectedMinPrice(value);
    } else {
      setSelectedMaxPrice(value);
    }
  };

  // console.log(filters);

  return (
    <div className='ps-0'>
      <div className="product-filter bg-white">
        {/* Filter Headline */}
        <h2 className="filter-headline">Filters</h2>

        {/* Categories Section */}
        <div className="category-filter">
          <h5>
            Categories
          </h5>
          <div className="accordion" id="accordionCategories">
            {categories.map((category) => (
              <div className="accordion-item" key={category.id}>
                <h2 className="accordion-header" id={`headingCat${category.id}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseCat${category.id}`}
                    aria-expanded="true"
                    aria-controls={`collapseCat${category.id}`}
                  >
                    {category.name}
                  </button>
                </h2>
                <div
                  id={`collapseCat${category.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`headingCat${category.id}`}
                  data-bs-parent="#accordionCategories"
                >
                  <div className="accordion-body" >
                    <ul>
                      <li key={category.id}>
                        <Link to={`/cat/${category.slug}`} className="subcategory-link">
                          {category.name}
                        </Link>
                      </li>
                      {category.subcategories?.map((sub) => (
                        <li key={sub.id}>
                          <Link to={`/cat/${sub.slug}`} className="subcategory-link">
                            {sub.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Price Section */}
        <div className="price-section mb-0 border-0">
          <h3>Price</h3>
          <div className="price-inputs">
            <label>
              Min:
              <input
                type="number"
                name="minPrice"
                value={selectedMinPrice}
                min={minPrice}
                max={maxPrice}
                onChange={handlePriceChange}
              />
            </label>
            <label>
              Max:
              <input
                type="number"
                name="maxPrice"
                value={selectedMaxPrice}
                min={minPrice}
                max={maxPrice}
                onChange={handlePriceChange}
              />
            </label>
          </div>
        </div>
      </div>

      {/* Brand Section */}
      <div className="accordion accordion-list" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingBrand">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseBrand"
              aria-expanded="false"
              aria-controls="collapseBrand"
            >
              BRAND
            </button>
          </h2>
          <div
            id="collapseBrand"
            className="accordion-collapse collapse"
            aria-labelledby="headingBrand"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul>
                {brands.map((brand) => (
                  <li key={brand.id}>
                    <Link to={`/brand/${brand.slug}`} className="brand-link">
                      {brand.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Other Filters (Customer Ratings, Availability, etc.) */}
        {/* Add similar accordion items here */}
      </div>
    </div >
  );
};

export default ProductFilter;
