import React, { useEffect, useState } from 'react';
import './Featured_Brands.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import api from './api'; // Ensure your custom Axios instance is correctly imported
import api from '../axiosConfig'; // Import the Axios instance
import { useNavigate, Link } from 'react-router-dom';


const options = {
  stagePadding: 50, // 4th item ka partial view
  responsive: {
    0: {
      items: 2, // Small screen mein 2 cards
    },
    768: {
      items: 3, // Larger screen mein 3 cards
    },
  },
  nav: true, // Show next and prev buttons
  dots: false, // Hide dots navigation
};

function Featured_Brands() {
  const [cardData, setCardData] = useState([]); // State to store featured brands
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    // Fetch featured brands from API
    const fetchFeaturedBrands = async () => {
      try {
        const response = await api.get('/api/featured_brands/');
        setCardData(response.data); // Assuming response.data is the array of featured brands
      } catch (error) {
        console.error('Error fetching featured brands:', error);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    fetchFeaturedBrands();
  }, []);

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="white-section">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="headline">Featured Brand</h4>
              <i className="fas fa-chevron-right right-chev"></i>
            </div>
            {loading ? (
              <div className="loading-spinner">Loading...</div> // Replace with your spinner or loader
            ) : (
              <OwlCarousel className="owl-theme" {...options}>
                {cardData.map((card, index) => (
                  <div className="item" key={index}>
                    <Link to={card.link} className="card text-start">
                      <div className="img-box">
                        <img
                          className="card-img-top"
                          src={card.image_url} // Assuming API provides `img` field
                          alt={card.title || 'Featured Brand'} // Provide a default alt text
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Featured_Brands;
