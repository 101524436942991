import React, { useEffect, useState } from 'react';
import api from '../axiosConfig'; // Import the Axios instance
// import './Ads.css';
import { useNavigate, Link } from 'react-router-dom';

function Ads() {
    const [ads, setAds] = useState([]); // Initialize ads as an empty array
    const [loading, setLoading] = useState(true);

    // Fetch ads from the API
    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await api.get('/api/ads/');
                setAds(response.data); // Set the ads data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching ads:', error);
                setLoading(false);
            }
        };

        fetchAds();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    if (ads.length === 0) {
        return <div>No ads available</div>; // Show a message if no ads are found
    }

    return (
        <div className="container-fluid">
            <div className='row'>
                {ads.map((ad, index) => (
                    <Link to={ad.link} key={index} className='col-lg-4 col-6 mb-4'>
                        <img className='img-fluid' src={ad.image_url} />
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Ads;
