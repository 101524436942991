import React, { useState } from 'react';
import {
    Button,
    Divider,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    FormControlLabel,
    Typography,
    useMediaQuery
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList'; // Filter Icon
import SortIcon from '@mui/icons-material/Sort'; // Sort Icon
import { useTheme } from '@mui/material/styles';

function AppFilter() {
    const [filterOpen, setFilterOpen] = useState(false);
    const [sortOpen, setSortOpen] = useState(false);
    const [activeSort, setActiveSort] = useState('popularity');

    // Use theme to get breakpoints
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is "small" or below

    // Open/Close Filter Modal
    const handleFilterToggle = () => {
        setFilterOpen(!filterOpen);
    };

    // Open/Close Sort Modal
    const handleSortToggle = () => {
        setSortOpen(!sortOpen);
    };

    // Handle sorting option change
    const handleSort = (sortOption) => {
        setActiveSort(sortOption);
        setSortOpen(false); // Close the dialog after selecting
    };

    if (!isMobile) {
        return null; // Return nothing if not mobile
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" padding={2}>
            {/* Filter Section */}
            <Button variant="outlined" color="error" onClick={handleFilterToggle} style={{ flex: 1 }}>
                <FilterListIcon /> Filter
            </Button>

            {/* Divider */}
            <Divider orientation="vertical" flexItem style={{ margin: '0 10px' }} />

            {/* Sort Section */}
            <Button variant="outlined" onClick={handleSortToggle} style={{ flex: 1 }}>
                <SortIcon /> Sort
            </Button>

            {/* Filter Modal */}
            <Dialog open={filterOpen} onClose={handleFilterToggle}>
                <DialogTitle>Filter Options</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">Price Range</Typography>
                    <FormControlLabel
                        control={<Radio />}
                        label="₹ 6001 and below"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label="₹ 6001 - ₹ 10000"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label="₹ 10001 - ₹ 15000"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label="₹ 15001 and above"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFilterToggle}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Sort Modal */}
            <Dialog open={sortOpen} onClose={handleSortToggle}>
                <DialogTitle>Sort By</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={<Radio checked={activeSort === 'popularity'} onChange={() => handleSort('popularity')} />}
                        label="Popularity"
                    />
                    <FormControlLabel
                        control={<Radio checked={activeSort === 'priceLowToHigh'} onChange={() => handleSort('priceLowToHigh')} />}
                        label="Price -- Low to High"
                    />
                    <FormControlLabel
                        control={<Radio checked={activeSort === 'priceHighToLow'} onChange={() => handleSort('priceHighToLow')} />}
                        label="Price -- High to Low"
                    />
                    <FormControlLabel
                        control={<Radio checked={activeSort === 'newest'} onChange={() => handleSort('newest')} />}
                        label="Newest First"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSortToggle}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AppFilter;
