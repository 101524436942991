import React, { useState } from 'react';
import './MobileNavbar.css';
import { Link, useNavigate } from 'react-router-dom';

function MobileNavbar() {
    const navigate = useNavigate(); // Initialize the navigate function
    const [isOpen, setIsOpen] = useState(false); // State to manage offcanvas open/close

    const handleMenuItemClick = (event, path) => {
        event.preventDefault(); // Prevent default anchor behavior
        navigate(path); // Perform routing logic
        setIsOpen(false); // Close the dropdown after clicking a menu item
    };

    return (
        <>
            <div className='side-bar'>
                <i
                    className="fas fa-bars d-block d-lg-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    onClick={() => setIsOpen(true)} // Open the offcanvas
                ></i>
                <div
                    className={`offcanvas offcanvas-start ${isOpen ? 'show' : ''}`}
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                    style={{ visibility: isOpen ? 'visible' : 'hidden' }} // Manage visibility
                >
                    <div className="offcanvas-header">
                        <h5><i className='fa fa-user'></i> Welcome</h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            onClick={() => setIsOpen(false)} // Close the offcanvas
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className='side-bar-list'>
                            <li onClick={(e) => handleMenuItemClick(e, '/allcategories')}> All Categories</li>
                            <li onClick={(e) => handleMenuItemClick(e, '/account')}><i className="fa fa-user"></i> Account</li>
                            <li onClick={(e) => handleMenuItemClick(e, '/my-order')}><i className="fa fa-list-alt"></i> My Order</li>
                            <li onClick={(e) => handleMenuItemClick(e, '/cart')}><i className="fa fa-shopping-cart"></i> My Cart</li>
                            <li onClick={(e) => handleMenuItemClick(e, '/wishlist')}><i className="fa fa-heart"></i> My Wishlist</li>
                            <li onClick={(e) => handleMenuItemClick(e, '/notification')}><i className="fa fa-bell"></i> My Notification</li>
                        </ul>
                        <ul className='list-unstyled ul2'>
                            <li className=''>Help Center</li>
                            <li className=''>Legal</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNavbar;
