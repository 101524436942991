import React, { useEffect, useState } from 'react';
import api from '../axiosConfig'; // Import the Axios instance
import './Banner.css';
import { useNavigate, Link } from 'react-router-dom';

function Banner() {
    const [banners, setBanners] = useState([]); // Initialize banners as an empty array
    const [loading, setLoading] = useState(true);

    // Fetch banners from the API
    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await api.get('/api/banners/');
                setBanners(response.data); // Set the banners data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching banners:', error);
                setLoading(false);
            }
        };

        fetchBanners();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    if (banners.length === 0) {
        return <div>No banners available</div>; // Show a message if no banners are found
    }

    return (
        <div className="container-fluid">
            {/* Mobile Banner */}
            <img
                className="img-fluid d-block d-lg-none"
                src={banners[0]?.image_url} // Use optional chaining to avoid errors
                alt={banners[0]?.title || 'Banner'}
            />

            {/* Desktop Carousel */}
            <div>
                <div
                    id="flipkartBanner"
                    className="carousel slide banner d-none d-lg-block"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        {banners.map((banner, index) => (
                            <Link
                                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                key={banner.id}
                                to={banner.link}
                            >
                                <img
                                    src={banner.image_url} // Dynamically display the banner image
                                    alt={banner.title || `Banner ${index + 1}`}
                                />
                            </Link>
                        ))}
                    </div>

                    {/* Carousel Controls */}
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#flipkartBanner"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#flipkartBanner"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Banner;
