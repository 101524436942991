import React, { useState, useEffect } from 'react';
import './Best_deal.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import api from '../axiosConfig'; // Import the Axios instance
import { useNavigate, Link } from 'react-router-dom';

const options = {
  responsive: {
    0: {
      items: 2, // Small screen will show 2 cards
    },
    768: {
      items: 6, // Larger screen will show 6 cards
    },
  },
  nav: true, // Show next and prev buttons
  dots: false, // Hide dots navigation
};

function Best_Deal() {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await api.get('/api/section/bottom');
        setSections(response.data || []); // Assuming API response is the array of sections
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sections:', error);
        setLoading(false);
      }
    };

    fetchSections();
  }, []);

  if (loading) {
    return <div className="loading">Loading Best Deals...</div>;
  }

  return (
    <div className='product_300'>
      <div className='container-fluid'>
        <div className='white-section'>
          {sections.map((section) => (
            <div key={section.id} className='section-block'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='headline'>{section.title}</h4>
                <i className="fas fa-chevron-right right-chev"></i>
              </div>
              <OwlCarousel className="owl-theme" {...options}>
                {section.items.map((item) => (
                  <div className="item" key={item.id}>
                    {/* <Link to={item.link} target="_blank" rel="noopener noreferrer"> */}
                    <Link to={item.link} >
                      <div className="card text-start">
                        <div className='img-box'>
                          <img className="card-img-top" src={item.image_url} alt={item.title} />
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">{item.title}</h4>
                          <p className="card-text black bold">{item.subtitle}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Best_Deal;
