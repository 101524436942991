import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../axiosConfig'; // Import the Axios instance
import './ProductDetails.css'
import Review from '../Review/Review';
import HoverDropdown from '../HoverDropdown/HoverDropdown';

const ProductDetails = () => {
    // State for product data
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get the product_slug from URL parameters
    const { product_slug } = useParams();

    useEffect(() => {
        // Fetch product details based on the product_slug
        const fetchProductDetails = async () => {
            try {
                const response = await api.get(`/api/product/${product_slug}`); // Replace with your actual endpoint
                setProduct(response.data); // Assuming the response has a `data` property
                setLoading(false);
            } catch (err) {
                setError('Error fetching product details');
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [product_slug]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <HoverDropdown />
            <div className="container mt-5 white-section section-padding">
                <div className="row pt-4">
                    {/* Product Image Section */}
                    <div className="col-md-5 product-image-container">
                        <img
                            src={product.image_url || 'default-image-url'} // Use actual product image URL
                            alt={product.name}
                            className="img-fluid"
                        />
                        <div className='buy-section-btn'>
                            <button className='btn-cart btn '>Add to Cart</button>
                            <button className='btn-buy btn '>Buy Now</button>
                        </div>
                    </div>

                    {/* Product Details Section */}
                    <div className="col-md-7 scroll-section">
                        <h2 className='product-title'>{product.name}</h2>
                        <p className='product-short-detail'>{product.short_description || product.description}</p>
                        <p className="text-danger mb-0">
                            <strong>Special Price</strong>
                        </p>
                        <h4 className='price-section'>
                            ₹{product.price} <span className="text-muted"><s className='real-price'>₹{product.mrp}</s></span> 
                            <span className='discount'>{product.discount_percentage}% off</span>
                        </h4>
                        <p>
                            <strong className='rating'>{product.rating}</strong> <span className="text-muted">/ 5 ({product.reviewsCount} reviews)</span>
                        </p>

                        <h5>Available Offers</h5>
                        <ul className='offer-list'>
                            {/* Assuming you can fetch or add offers dynamically */}
                            {product.offers && product.offers.map((offer, index) => (
                                <li key={index}>{offer}</li>
                            ))}
                        </ul>

                        {/* Color and Size Selection */}
                        <div className="mt-4">
                            <h5>Color: {product.color}</h5>
                            <h5>Size: {product.size}</h5>
                        </div>

                        {/* Product Details Accordion */}
                        <div className='mt-4'>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            Product Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="container mt-4">
                                                <h2>Product Details</h2>
                                                {/* Product Detail Rows */}
                                                {Object.entries(product.details || {}).map(([key, value], index) => (
                                                    <div key={index} className="row product-info-row">
                                                        <div className="col-md-4 product-info-label">{key}</div>
                                                        <div className="col-md-8 product-info-value">{value}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Product Review */}
                        <div>
                            <Review />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
