import React, { useEffect, useState } from 'react';
import './produts_300.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate, Link } from 'react-router-dom';
import api from '../axiosConfig'; // Import Axios instance

const options = {
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 6,
    },
  },
  nav: true,
  dots: false,
};

function Product_under_300() {
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchsections = async () => {
      try {
        const response = await api.get('/api/section/top'); // API endpoint to fetch sections under 300
        setSections(response.data || []); // Update the state with fetched sections
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sections:', error);
        setLoading(false);
      }
    };

    fetchsections();
  }, []);

  // const handleCardClick = (productId) => {
  //   navigate(`/productlist/${productId}`); // Navigate to product details or list page with product ID
  // };

  const handleCardClick = (link) => {
    navigate(link); // Navigate to product details or list page with product ID
  };

  if (loading) {
    return <div className="loading">Loading sections...</div>;
  }

  return (
    <>
      <div className='product_300'>
        <div className='container-fluid'>
          <div className='white-section'>
            {sections.map((section) => (
              <div key={section.id} className='section-block'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 className='headline'>{section.title}</h4>
                  <Link to={section.link}>
                    <i className="fas fa-chevron-right right-chev"></i>
                  </Link>
                </div>
                <OwlCarousel className="owl-theme" {...options}>
                  {section.items.map((item, index) => (
                    // <div onClick={() => handleCardClick(item.link)} className="item" key={index}>
                    <Link to={item.link} className="item" key={index}>
                      <div className="card text-start">
                        <div className='img-box'>
                          <img className="card-img-top" src={item.image_url} alt={item.title} />
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">{item.title}</h4>
                          <p className="card-text black bold">{item.subtitle}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </OwlCarousel>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Product_under_300;
